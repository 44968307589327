import { Box, BoxProps, SpaceName } from '@air/zephyr';

import { ASSET_STATE_TEXT, EXT_PREVIEW_BOX } from '~/constants/testIDs';
import { ClipIconSize } from '~/types/clip-icon-size';
import { getExtStyles } from '~/utils/FileExtensionUtils';

export type FileExtensionPreviewSize = 'small' | 'mediumSmall' | 'medium' | 'large';
export type FileExtensionPreviewColor = 'gray' | 'color' | 'default';

export interface FileExtensionPreviewProps extends Pick<BoxProps, 'tx'> {
  /** clip extension used to differentiate file icons and colors */
  ext: string;
  /** text we want to show below the icon */
  text?: string;
  /** color of icon & text with three types */
  color?: FileExtensionPreviewColor;
  /** size of icon & text with three types */
  size?: FileExtensionPreviewSize;
}

/**
 * This component is used to to differentiate files by icon and color based on the file's extension.
 * Styling is different for icons on the modal, in file table view, and file gallery view.
 * Icons are grey when processing a new upload.
 */
const FileExtensionPreview = ({ ext, color = 'color', size = 'small', text, tx }: FileExtensionPreviewProps) => {
  const sizeMap: { [key in string]: { icon: ClipIconSize; font?: 8 | 10 | 12; textMarginTop?: SpaceName } } = {
    small: {
      icon: ClipIconSize.small,
      font: 8,
      textMarginTop: '2px',
    },
    mediumSmall: {
      icon: ClipIconSize.mediumSmall,
      font: 10,
      textMarginTop: '2px',
    },
    medium: {
      icon: ClipIconSize.medium,
      font: 12,
      textMarginTop: '10px',
    },
  };

  const { iconName, iconComponent, iconColor, modalIconColor } = getExtStyles(ext);

  const colorMap: { [key in string]: string } = {
    gray: 'pigeon400',
    color: iconColor,
    default: modalIconColor,
  };

  const extIconColor = 'currentColor';

  return (
    <Box
      data-testid={EXT_PREVIEW_BOX}
      color={colorMap[color]}
      tx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', color: colorMap[color], ...tx }}
    >
      <Box
        as={iconComponent}
        data-testid={iconName}
        tx={{ width: sizeMap[size].icon, color: extIconColor, display: 'block' }}
      />
      {!!text && (
        <div
          className="text-center text-14 font-bold uppercase"
          data-testid={ASSET_STATE_TEXT}
          style={{
            marginTop: sizeMap[size].textMarginTop!,
            // using fontSize because size 8 font (uppercased is a visual 10) is not in our TextVariant system
            fontSize: sizeMap[size].font!,
          }}
        >
          {text}
        </div>
      )}
    </Box>
  );
};

export default FileExtensionPreview;
