import { Box, BoxProps, ColorName, Text } from '@air/zephyr';

export type AvatarSizes = 16 | 20 | 24 | 28 | 32 | 36 | 40 | 48 | 56 | 96;

export type AvatarTypes = 'default' | 'imgix';

export type AvatarProps = Omit<BoxProps, 'children'> & {
  appearance?: 'circle' | 'square';
  size?: AvatarSizes;
  text?: string;
  type?: AvatarTypes;
  disabled?: boolean;
  colorSeed?: string | null;
} & (
    | {
        alt: string;
        src: string | null;
      }
    | {
        alt?: string;
        src?: string | null;
      }
  );

const getTextVariant = (size: AvatarProps['size']) => {
  if (size === 16 || size === 20) {
    return 'text-ui-8-uppercase';
  } else if (size === 24 || size === 28) {
    return 'text-ui-10-uppercase';
  } else if (size === 32) {
    return 'text-ui-12-uppercase';
  } else {
    return 'text-ui-14-uppercase';
  }
};

const getImageSrc = (url: string, size: AvatarSizes, type: AvatarTypes) => {
  switch (type) {
    case 'imgix':
      return `${url}?q=80&w=${size * 2}&h=${size * 2}`;
    default:
      return url;
  }
};

const styleMap: { color: ColorName; bg: ColorName }[] = [
  { color: 'flamingo900', bg: 'flamingo200' },
  { color: 'flamingo900', bg: 'flamingo300' },
  { color: 'jay700', bg: 'macaw060' },
  { color: 'white', bg: 'macaw350' },
  { color: 'white', bg: 'cockatoo300' },
  { color: 'cockatoo800', bg: 'cockatoo050' },
  { color: 'cuckoo900', bg: 'cuckoo300' },
  { color: 'white', bg: 'cuckoo600' },
  { color: 'canary900', bg: 'canary100' },
  { color: 'canary900', bg: 'canary300' },
  { color: 'canary900', bg: 'robin400' },
  { color: 'canary900', bg: 'canary600' },
  { color: 'peacock900', bg: 'parrot050' },
  { color: 'peacock900', bg: 'parrot300' },
  { color: 'jay700', bg: 'peacock200' },
  { color: 'jay700', bg: 'peacock400' },
  { color: 'pigeon600', bg: 'pigeon100' },
  { color: 'pigeon050', bg: 'pigeon700' },
];

/**
 * Takes `colorSeed` (e.g. meant to be a user ID) and returns the same randomly chosen style from the list above consistently each time it gets called.
 * Borrowing logic from randomColor script by @davidmerfield
 * @see: https://github.com/davidmerfield/randomColor/blob/a045a83a2ee0de85ed5448b938ff4d6ef18a397e/randomColor.js#L291
 */
const getRandomStyle = (colorSeed: string): { color: ColorName; bg: ColorName } => {
  const stringToInteger = () => {
    let total = 0;
    for (let i = 0; i !== colorSeed.length; i++) {
      if (total >= Number.MAX_SAFE_INTEGER) break;
      total += colorSeed.charCodeAt(i);
    }
    return total;
  };

  let seed = stringToInteger();

  /**
   * Seeded random algorithm from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
   */
  const randomWithin = () => {
    const max = styleMap.length - 1;
    const min = 0;
    seed = (seed * 9301 + 49297) % 233280;
    const rnd = seed / 233280;
    return Math.floor(min + rnd * (max - min));
  };
  return styleMap[randomWithin()];
};

export const Avatar = ({
  alt,
  appearance,
  size = 40,
  src,
  text,
  type = 'default',
  disabled = false,
  colorSeed,
  tx,
  ...restOfProps
}: AvatarProps) => {
  return (
    <Box
      tx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bg: 'pigeon025',
        width: size,
        height: size,
        minWidth: size,
        borderRadius: appearance === 'square' ? 4 : 999,
        color: !text ? 'pigeon100' : 'pigeon700',
        objectFit: 'cover',
        cursor: 'default',
        overflow: 'hidden',
        filter: disabled ? 'grayscale(100%)' : undefined,
        opacity: disabled ? 0.7 : 1,
        ...(colorSeed ? getRandomStyle(colorSeed) : undefined),
        ...tx,
      }}
      {...restOfProps}
    >
      {src ? (
        <img
          alt={alt}
          className="block object-cover"
          src={getImageSrc(src, size, type)}
          style={{ width: size, height: size }}
        />
      ) : text ? (
        <Text tx={{ color: 'inherit', fontWeight: 'bold' }} variant={getTextVariant(size)}>
          {text}
        </Text>
      ) : (
        <svg className="h-full w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M16 19a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0 26c6.627 0 12-5.373 12-12s-5.373-12-12-12S4 26.373 4 33s5.373 12 12 12Z"
          />
        </svg>
      )}
    </Box>
  );
};
