import { AirActionTrackingLocation, useTrackClickedAddContent } from '@air/analytics';
import {
  ContextMenu as ContextMenuPrimitive,
  type ContextMenuOption,
  type ContextMenuOptionItem,
  renderContextItems,
} from '@air/primitive-context-menu';
import { MouseEvent as ReactMouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { contextMenu } from 'react-contexify';
import { isHotkeyPressed } from 'react-hotkeys-hook';

export interface UseContextMenuInfo {
  openContextMenu: (event: ReactMouseEvent<HTMLElement>) => void;
  ContextMenu: ReactNode;
  getContextMenu: () => ReactNode;
  isShowingContextMenu: boolean;
}

export interface UseContextMenuParams {
  childrenTop?: ReactNode;
  id: string;
  options: ContextMenuOption[];
  trackLocation?: AirActionTrackingLocation;
  onContextMenuOpenChange?: (isOpen: boolean) => void;
}
/**
 * Use this hook to show context menu.
 * For vercel and local builds it might be disabled by pressing alt
 * @param menuId - id of context menu. Must be unique
 * @param options - options to show in menu
 */
export function useContextMenu({
  childrenTop,
  id,
  options,
  trackLocation = 'board-container-right-click',
  onContextMenuOpenChange,
}: UseContextMenuParams): UseContextMenuInfo {
  const { trackClickedAddContent } = useTrackClickedAddContent();
  const [isShowingContextMenu, setIsShowing] = useState(false);

  const onIsShowingContextMenuChange = useCallback(
    (isShowing: boolean) => {
      setIsShowing(isShowing);
      onContextMenuOpenChange?.(isShowing);
    },
    [setIsShowing, onContextMenuOpenChange],
  );

  const openContextMenu = useCallback(
    (event: ReactMouseEvent<HTMLElement>) => {
      if (isHotkeyPressed('alt')) {
        return;
      }
      event.stopPropagation();
      event.preventDefault();

      if (options.length) {
        contextMenu.hideAll();
        trackClickedAddContent({ location: trackLocation });
        contextMenu.show({
          id,
          event,
        });
      }
    },
    [id, options.length, trackClickedAddContent, trackLocation],
  );

  const handleItemClick = (onSelect: ContextMenuOptionItem['onSelect']) => {
    return () => {
      contextMenu.hideAll();
      onSelect?.();
    };
  };

  const ContextMenu = useMemo(() => {
    return (
      <ContextMenuPrimitive
        id={id}
        onShown={() => onIsShowingContextMenuChange(true)}
        onHidden={() => onIsShowingContextMenuChange(false)}
      >
        {childrenTop}
        {renderContextItems({
          options: options.map((option) => {
            if (option.type === 'item') {
              return {
                ...option,
                onSelect: handleItemClick(option?.onSelect),
              };
            }

            return option;
          }),
        })}
      </ContextMenuPrimitive>
    );
  }, [childrenTop, id, options, onIsShowingContextMenuChange]);

  const getContextMenu = useCallback(() => {
    return (
      <ContextMenuPrimitive
        id={id}
        onShown={() => onIsShowingContextMenuChange(true)}
        onHidden={() => onIsShowingContextMenuChange(false)}
      >
        {childrenTop}
        {renderContextItems({
          options: options.map((option) => {
            if (option.type === 'item') {
              return {
                ...option,
                onSelect: handleItemClick(option?.onSelect),
              };
            }

            return option;
          }),
        })}
      </ContextMenuPrimitive>
    );
  }, [childrenTop, id, options, onIsShowingContextMenuChange]);

  return {
    openContextMenu,
    ContextMenu,
    isShowingContextMenu,
    getContextMenu,
  };
}
