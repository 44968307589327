import { Portal } from '@air/primitive-portal';
import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import { Menu, MenuProps } from 'react-contexify';

export const contextMenu = tailwindVariants({
  base: 'fixed z-10 flex w-[216px] min-w-[var(--radix-popper-anchor-width)] animate-slide-up flex-col gap-2 rounded bg-grey-1 p-2 text-grey-12 shadow-popover outline-none',
  variants: {
    size: {
      small: 'w-[216px]',
      large: 'w-[240px]',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

export type ContextMenuVariant = VariantProps<typeof contextMenu>;

export type ContextMenuProps = MenuProps & ContextMenuVariant;

export const ContextMenu = ({ children, className, size, ...restOfProps }: ContextMenuProps) => {
  return (
    <Portal>
      <Menu
        animation={false}
        className={contextMenu({ size, className })}
        data-context-menu
        data-testid="CONTEXT_MENU"
        {...restOfProps}
      >
        {children}
      </Menu>
    </Portal>
  );
};
