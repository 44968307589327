import { Text } from '@air/zephyr';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Image from 'next/legacy/image';
import ReactDOM from 'react-dom';

import CatDoingThings from '~/assets/cat-doing-things.svg';
import { Logo } from '~/components/Logo';
import { MAINTENANCE_MODE_CONTAINER } from '~/constants/testIDs';

interface MaintenanceModeProps {
  children: JSX.Element;
}

export const MaintenanceMode = ({ children }: MaintenanceModeProps) => {
  const { maintenanceModeJson } = useFlags();

  if (maintenanceModeJson?.maintenanceMessage) {
    return ReactDOM.createPortal(
      <div
        className="fixed left-0 top-0 z-10 flex h-full w-full flex-col items-center bg-white px-8"
        data-testid={MAINTENANCE_MODE_CONTAINER}
      >
        <Logo tx={{ fill: 'jay600', width: 95, my: 32 }} />
        <Image alt="A cat doing things" src={CatDoingThings} height={295} width={496} />
        <div className="max-w-[560px]">
          <Text tx={{ fontWeight: 600, textAlign: 'center' }} variant="text-heading-40">
            Air is currently unavailable
          </Text>
          <div className="text-center text-20 text-pigeon-500">{maintenanceModeJson.maintenanceMessage}</div>
        </div>
      </div>,
      document.body,
    );
  }

  return children;
};
