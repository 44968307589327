import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';

import { SELECT_CHIP_REMOVE_BTN } from '~/components/Zephyr/Select/shared/testIDs';
import { DefaultChipType } from '~/components/Zephyr/Select/shared/types';

interface SelectChipRemoveButtonProps<T extends DefaultChipType> {
  chip: T;
  onClick: (chip: T) => void;
}

export const SelectChipRemoveButton = <T extends DefaultChipType>({
  chip,
  onClick,
}: SelectChipRemoveButtonProps<T>) => {
  return (
    <IconButton
      appearance="ghost"
      color="grey"
      className="ml-0.5"
      data-testid={`${SELECT_CHIP_REMOVE_BTN}-${chip.label}`}
      icon={Close}
      label="Remove chip"
      onClick={(event) => {
        event.stopPropagation();
        onClick(chip);
      }}
      size="extra-small"
    />
  );
};
