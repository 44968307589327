import { Box, TXProp } from '@air/zephyr';
import { forwardRef, MouseEvent, useCallback, useMemo } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { DateInputField, DateInputFieldProps } from '~/components/Zephyr/DateInput/DateInputField';
import { DateInputHeader } from '~/components/Zephyr/DateInput/DateInputHeader';

import { DatePickerStyles } from './ui';

export interface DateInputProps extends Omit<ReactDatePickerProps, 'onChange' | 'selectsRange'> {
  label: string;
  name: string;
  onChange: (date?: Date) => void;
  showClearButton?: boolean;
  tx?: TXProp;
  variant?: DateInputFieldProps['variant'];
  shouldShowCalendarIcon?: boolean;
  testId?: string;
}

export const DateInput = forwardRef<HTMLDivElement | null, DateInputProps>(
  (
    {
      shouldShowCalendarIcon = true,
      onChange,
      label,
      name,
      showClearButton = true,
      tx,
      variant,
      ...props
    }: DateInputProps,
    ref,
  ) => {
    const onDateChange: ReactDatePickerProps['onChange'] = useCallback(
      (date) => {
        onChange(date as Date);
      },
      [onChange],
    );

    const onClear = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onDateChange(null, e);
      },
      [onDateChange],
    );

    const renderCustomHeader = useCallback<Required<ReactDatePickerProps>['renderCustomHeader']>(
      (params) => <DateInputHeader {...params} />,
      [],
    );

    const styles = useMemo(() => ({ ...DatePickerStyles, ...tx }), [tx]);

    const { testId, ...restProps } = props;

    return (
      <Box tx={styles}>
        <ReactDatePicker
          showPopperArrow={false}
          onChange={onDateChange}
          customInput={
            <DateInputField
              shouldShowCalendarIcon={shouldShowCalendarIcon}
              name={name}
              label={label}
              ref={ref}
              variant={variant}
              onClear={showClearButton ? onClear : undefined}
              data-testid={testId}
            />
          }
          renderCustomHeader={renderCustomHeader}
          {...restProps}
        />
      </Box>
    );
  },
);

DateInput.displayName = 'DateInput';
