import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import { type ComponentPropsWithoutRef, memo } from 'react';

export const contextMenuLabel = tailwindVariants({
  base: 'text-12 font-bold uppercase tracking-wide text-grey-10',
  variants: {
    size: {
      small: 'px-1.5',
      large: 'px-2',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

export type ContextMenuLabelVariants = VariantProps<typeof contextMenuLabel>;

export type ContextMenuLabelProps = ComponentPropsWithoutRef<'div'> & ContextMenuLabelVariants;

export const ContextMenuLabel = memo(({ className, size = 'small', ...restOfProps }: ContextMenuLabelProps) => {
  return <div className={contextMenuLabel({ size, className })} data-testid="CONTEXT_MENU_LABEL" {...restOfProps} />;
});

ContextMenuLabel.displayName = 'ContextMenuLabel';
