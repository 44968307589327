import './styles.css';

import { ChevronRight } from '@air/next-icons';
import { type VariantProps } from '@air/tailwind-variants';
import classNames from 'classnames';
import { Submenu, type SubMenuProps } from 'react-contexify';

import { contextMenu } from './ContextMenu';
import { contextMenuItem, type ContextMenuItemProps } from './ContextMenuItem';

export type ContextMenuSubVariant = VariantProps<typeof contextMenu>;

export type ContextMenuSubProps = ContextMenuSubVariant &
  Omit<SubMenuProps, 'label' | 'prefix'> &
  Pick<ContextMenuItemProps, 'description' | 'label' | 'prefix'>;

export const ContextMenuSub = ({
  children,
  className,
  description,
  label,
  prefix,
  size,
  ...restOfProps
}: ContextMenuSubProps) => {
  const hasDescription = !!description;
  const isSmall = size === 'small';

  return (
    <Submenu
      arrow={false}
      label={
        <div className={contextMenuItem({ size })}>
          {prefix ? (
            <div className={classNames('mr-2 shrink-0', hasDescription ? (isSmall ? 'mt-0.5' : 'mt-1') : 'mt-0')}>
              {prefix}
            </div>
          ) : null}

          <div className="flex min-w-0 grow flex-col">
            <span className={classNames('truncate', isSmall ? 'text-14' : 'text-16')}>{label}</span>
            {hasDescription ? (
              <span className={classNames('text-pigeon-500', isSmall ? 'text-12' : 'text-14')}>{description}</span>
            ) : null}
          </div>
          <div className={classNames('ml-2 shrink-0', hasDescription ? (isSmall ? 'mt-0.5' : 'mt-1') : 'mt-0')}>
            <ChevronRight className="h-4 w-4" />
          </div>
        </div>
      }
      {...restOfProps}
    >
      <div className={contextMenu({ className, size })}>{children}</div>
    </Submenu>
  );
};
