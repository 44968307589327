import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import classNames from 'classnames';
import { type ReactNode } from 'react';
import { Item, type ItemProps } from 'react-contexify';

export const contextMenuItem = tailwindVariants({
  base: 'flex h-8 shrink-0 cursor-pointer items-center rounded px-1.5 text-inherit outline-none radix-disabled:cursor-not-allowed hover:bg-grey-4 disabled:bg-transparent disabled:opacity-50',
  variants: {
    description: {
      false: 'items-center py-0',
      true: 'items-start py-1.5',
    },
    size: {
      small: 'h-8',
      large: 'h-9',
    },
  },
  compoundVariants: [
    {
      description: true,
      size: 'small',
      className: 'h-auto',
    },
    {
      description: true,
      size: 'large',
      className: 'h-auto',
    },
  ],
  defaultVariants: {
    description: false,
    size: 'small',
  },
});

export type ContextMenuItemVariant = VariantProps<typeof contextMenuItem>;

export type ContextMenuItemProps = Omit<ContextMenuItemVariant, 'description'> &
  Omit<ItemProps, 'children' | 'onClick' | 'prefix'> &
  Partial<Pick<ItemProps, 'children'>> & {
    description?: ReactNode;
    label?: ReactNode;
    onSelect?: () => void;
    prefix?: ReactNode;
    suffix?: ReactNode;
    shortcut?: string[];
  };

export const ContextMenuItem = ({
  children,
  className,
  description,
  label,
  onSelect,
  prefix,
  shortcut,
  size = 'small',
  suffix,
  ...restOfProps
}: ContextMenuItemProps) => {
  const hasDescription = !!description;
  const isSmall = size === 'small';

  return (
    <Item data-testid="CONTEXT_MENU_ITEM" onClick={onSelect} {...restOfProps}>
      <div className={contextMenuItem({ size, className, description: hasDescription })}>
        {!!children ? (
          children
        ) : (
          <>
            {!!prefix ? (
              <div className={classNames('mr-2 shrink-0', hasDescription ? (isSmall ? 'mt-0.5' : 'mt-1') : 'mt-0')}>
                {prefix}
              </div>
            ) : null}

            <div className="flex min-w-0 grow flex-col">
              <span className={classNames('truncate', isSmall ? 'text-14' : 'text-16')}>{label}</span>
              {hasDescription ? (
                <span className={classNames('text-grey-10', isSmall ? 'text-12' : 'text-14')}>{description}</span>
              ) : null}
            </div>

            {!!suffix ? (
              <div className={classNames('ml-2 shrink-0', hasDescription ? (isSmall ? 'mt-0.5' : 'mt-1') : 'mt-0')}>
                {suffix}
              </div>
            ) : null}
            {!!shortcut ? (
              <div className="ml-2 flex shrink-0 items-center">
                {shortcut.map((key, index) => (
                  <kbd
                    className="mr-0.5 flex h-4 w-4 items-center justify-center rounded bg-grey-3 text-10 uppercase text-grey-8 last:mr-0"
                    key={index}
                  >
                    {key}
                  </kbd>
                ))}
              </div>
            ) : null}
          </>
        )}
      </div>
    </Item>
  );
};
