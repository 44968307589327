import { Calendar } from '@air/next-icons';
import { Box } from '@air/zephyr';
import { forwardRef, MouseEvent } from 'react';

import { Input, InputProps } from '~/components/Zephyr/Input';

import { InputClearButton } from '../InputClearButton';

export interface DateInputFieldProps extends InputProps {
  onClear?: (e: MouseEvent<HTMLButtonElement>) => void;
  shouldShowCalendarIcon?: boolean;
}

export const DateInputField = forwardRef<HTMLDivElement | null, DateInputFieldProps>(
  ({ shouldShowCalendarIcon = true, onClear, ...props }: DateInputFieldProps, ref) => {
    return (
      <>
        <Input
          leftAdornment={
            shouldShowCalendarIcon && (
              <Box
                as={Calendar}
                tx={{
                  color: 'var(--colors-grey11)',
                  width: 16,
                  height: 16,
                  fontSize: 10,
                  '& path': {
                    strokeWidth: 3.5,
                  },
                }}
              />
            )
          }
          leftAdornmentClickThrough
          spacingStyles={{
            minWidth: 110,
            // TODO: This fixes incorrect alignment for leftAdornment icon - remove it after fix in Input
            // @ts-ignore
            '> div': { lineHeight: 1.15 },
          }}
          isLabelHidden={true}
          ref={ref}
          {...props}
        />
        {onClear && <InputClearButton onClick={onClear} isVisible={!!props.value} />}
      </>
    );
  },
);

DateInputField.displayName = 'DateInputField';
