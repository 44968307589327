import { createSelector } from 'reselect';

import { activeUploadStates, pausableStates } from './constants';
import { initialUploadProgress, Upload, UploaderState, UploaderStore, UploadStatus } from './types';
import { isFinishedUploading, isLarge, uploadHasUploadInfo } from './utils';

const uploaderSelector = ({ uploader }: UploaderStore): UploaderState => uploader;
const uploadIdSelector = (_state: UploaderStore, uploadId: Upload['id']) => uploadId;

export const uploadArraySelector = createSelector(uploaderSelector, ({ uploadsArray }) => uploadsArray);

const currentUploadingInfoSelector = createSelector(
  uploaderSelector,
  ({ currentUploadingInfo }) => currentUploadingInfo,
);

export const uploadingSpeedSelector = createSelector(currentUploadingInfoSelector, ({ speed }) => speed);

export const pauseableUploadsSelector = createSelector(uploadArraySelector, (uploads): Upload[] =>
  uploads.filter((u) => pausableStates.includes(u.status)),
);

export const uploadsCurrentlyUploadingSelector = createSelector(uploadArraySelector, (uploads): Upload[] =>
  uploads.filter((u) => u.status === UploadStatus.uploading || u.status === UploadStatus.preparing),
);

export const hasCurrentlyUploadingUploadsSelector = createSelector(
  uploadsCurrentlyUploadingSelector,
  (uploads) => !!uploads.length,
);

export const activeUploadsSelector = createSelector(uploadArraySelector, (uploads): Upload[] =>
  uploads.filter((u) => activeUploadStates.includes(u.status)),
);

export const pausedUploadsSelector = createSelector(uploadArraySelector, (uploads): Upload[] =>
  uploads.filter((u) => u.status === UploadStatus.paused),
);

export const hasPausedUploadsSelector = createSelector(
  pausedUploadsSelector,
  (pausedUploads) => pausedUploads.length > 0,
);

export const queuedUploadsSelector = createSelector(uploadArraySelector, (uploads): Upload[] =>
  uploads.filter((u) => u.status === UploadStatus.queued),
);

export const notStartedUploadsIdsSelector = createSelector(uploadArraySelector, (uploads) =>
  uploads.reduce((acc, curr) => {
    if (!curr.clipId) {
      acc.push(curr.id);
    }
    return acc;
  }, [] as string[]),
);

export const activeNotCompletedUploadsSelector = createSelector(uploadArraySelector, (uploads) =>
  uploads.reduce((acc, curr) => {
    if (
      curr.status === UploadStatus.uploading ||
      curr.status === UploadStatus.queued ||
      curr.status === UploadStatus.preparing ||
      curr.status === UploadStatus.paused
    ) {
      acc.push(curr);
    }
    return acc;
  }, [] as Upload[]),
);

export const failedUploadsSelector = createSelector(uploadArraySelector, (uploads): Upload[] =>
  uploads.filter((u) => u.status === UploadStatus.failed),
);

export const hasUploadsSelector = createSelector(
  uploadArraySelector,
  (uploads) => uploads.filter(({ status }) => status !== UploadStatus.aborted).length > 0,
);

export const uploaderIsUploadingSelector = createSelector(
  uploadArraySelector,
  (uploads): boolean =>
    !!uploads.filter(
      (u) =>
        u.status === UploadStatus.uploading || u.status === UploadStatus.queued || u.status === UploadStatus.preparing,
    ).length,
);

export const nextUploadIdSelector = createSelector(queuedUploadsSelector, (uploads) => uploads[0]?.id);

export const isFinishedUploadingSelector = createSelector(uploadArraySelector, (uploads) =>
  isFinishedUploading(uploads),
);

export const allUploadProgressSelector = createSelector([uploaderSelector], ({ uploadProgress }) => uploadProgress);

export const totalUploadedSizeSelector = createSelector(
  currentUploadingInfoSelector,
  ({ uploadedBytes }) => uploadedBytes,
);

export const totalUploadSizeLeftSelector = createSelector(
  pauseableUploadsSelector,
  allUploadProgressSelector,
  (uploads, uploadsProgress) =>
    uploads.reduce((acc, curr) => {
      if (curr.clipId && uploadsProgress[curr.id]) {
        const uploadLeft = curr.file.size - uploadsProgress[curr.id].progress;
        return acc + uploadLeft;
      }
      return acc + curr.file.size;
    }, 0),
);

export const uploadProgressSelector = createSelector(
  [allUploadProgressSelector, uploadIdSelector],
  (uploadProgress, uploadId) => uploadProgress[uploadId] || initialUploadProgress,
);

export const uploadByIdSelector = createSelector(uploadArraySelector, uploadIdSelector, (uploads, uploadId) =>
  uploads.find(({ id }) => id === uploadId),
);

export const largeUploadByIdSelector = createSelector(uploadArraySelector, uploadIdSelector, (uploads, uploadId) => {
  const upload = uploads.find(({ id }) => id === uploadId);
  if (!!upload && isLarge(upload)) {
    return upload;
  }
});

export const largeUploadWithS3InfoByIdSelector = createSelector(largeUploadByIdSelector, (upload) => {
  if (upload && uploadHasUploadInfo(upload)) {
    return upload;
  }
});

export const smallUploadByIdSelector = createSelector(uploadArraySelector, uploadIdSelector, (uploads, uploadId) => {
  const upload = uploads.find(({ id }) => id === uploadId);
  if (!!upload && !isLarge(upload)) {
    return upload;
  }
});

export const uploadedPartsSelector = createSelector(uploadByIdSelector, (upload) => upload?.s3Info.uploadedParts ?? []);
