import { theme, TXProp } from '@air/zephyr';
import { rgba } from 'polished';

export const DatePickerStyles: TXProp = {
  '.react-datepicker': {
    p: 12,
    borderRadius: 4,
    boxShadow: `0px 2px 8px ${rgba(0, 0, 0, 0.2)},
     0px 1px 3px ${rgba(0, 0, 0, 0.15)},
     0px 0px 2px ${rgba(0, 0, 0, 0.25)}`,

    '&__header': {
      p: 0,
      bg: 'white',
      borderBottom: 'none',
    },

    '&__month': {
      p: 0,
      m: 0,
    },

    '&__day-names': {
      m: 0,
    },

    '&__day-name, &__day': {
      height: 24,
      width: 24,
      lineHeight: '24px',
    },

    '&__day-name': {
      color: theme.colors.pigeon400,
      fontSize: 10,
      fontWeight: 700,
      width: 32,
      height: 24,
      m: 0,
    },

    '&__day': {
      color: theme.colors.pigeon600,
      m: 4,
      fontWeight: 400,
      fontSize: 12,
      borderRadius: 24,

      '&--selected, &--keyboard-selected, &--in-range, &--in-selecting-range': {
        color: theme.colors.white,
        fontWeight: 600,
        bg: theme.colors.jay500,
      },

      '&--outside-month': {
        color: theme.colors.pigeon300,
      },

      '&--in-selecting-range:not(&--in-range)': {
        color: theme.colors.white,
        bg: theme.colors.jay200,
      },
    },
  },
};
