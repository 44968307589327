import { tailwindMerge } from '@air/tailwind-variants';
import { Box, ColorName, FieldVariantName } from '@air/zephyr';
import { memo, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { SelectChipRemoveButton } from '~/components/Zephyr/Select/components/SelectChipRemoveButton';
import { SELECT_CHIP } from '~/components/Zephyr/Select/shared/testIDs';
import { SelectVariantStyles } from '~/components/Zephyr/Select/shared/variantsStyles';

import { DefaultChipType } from '../shared/types';

interface MultiSelectChipProps<T extends DefaultChipType> {
  chip: T;
  onDeselect?: (deselected: T) => void;
  'data-testid'?: string;
  variant?: FieldVariantName;
}

const _SelectChip = <T extends DefaultChipType>({
  chip,
  onDeselect,
  'data-testid': testId = SELECT_CHIP,
  variant = 'field-input-chonky',
}: MultiSelectChipProps<T>) => {
  const theme = useTheme();
  const chipColor = useMemo(() => {
    if (!!chip.backgroundHexColor) {
      const themeColor = theme.colors[chip.backgroundHexColor as ColorName];
      if (!!themeColor) return themeColor;
      return chip.backgroundHexColor;
    }
    return 'var(--colors-grey4)';
  }, [chip.backgroundHexColor, theme.colors]);

  return (
    <Box
      tx={{
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: chipColor,
        borderRadius: chip.borderRadius || 40,
        transition: 'background 0.125s ease-in-out',
        mr: 6,
        my: 3,
        ...SelectVariantStyles[variant].chip.tx,
      }}
    >
      <div
        className={tailwindMerge(
          'truncate font-medium text-grey-11',
          variant === 'field-input-smol' ? 'text-12' : 'text-14',
        )}
        data-testid={`${testId}-${chip.label}`}
        style={{
          color: chip.fontHexColor,
        }}
      >
        {chip.label}
      </div>
      {!!onDeselect && <SelectChipRemoveButton chip={chip} onClick={onDeselect} />}
    </Box>
  );
};

export const SelectChip = memo(_SelectChip) as typeof _SelectChip;
