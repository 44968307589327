import { Box, ColorName, TXProp } from '@air/zephyr';
import { rgba } from 'polished';
import { memo } from 'react';
import styled, { keyframes, useTheme } from 'styled-components';

import { LOADING_SPINNER } from '~/constants/testIDs';

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

interface SpinStyleProps {
  stroke?: number;
  ringColor?: string;
  highlightColor?: string;
  size?: number;
}

const SpinStyle = styled.div<SpinStyleProps>`
  border: ${({ stroke }) => stroke}px solid ${({ ringColor }) => ringColor};
  border-top: ${({ stroke }) => stroke}px solid ${({ highlightColor }) => highlightColor};
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  animation: ${spin} 0.6s linear infinite;
`;

export interface SpinnerProps {
  size?: number;
  stroke?: number;
  ringColor?: ColorName;
  highlightColor?: ColorName;
  highlightColorAlpha?: number;
  tx?: TXProp;
  dataTestId?: string;
}

const Spinner = memo(
  ({
    size = 32,
    stroke = 3,
    ringColor = 'pigeon200',
    highlightColor = 'peacock500',
    highlightColorAlpha,
    tx,
    dataTestId,
  }: SpinnerProps) => {
    const theme = useTheme();

    return (
      <Box
        as={SpinStyle}
        size={size}
        stroke={stroke}
        ringColor={theme.colors[ringColor]}
        highlightColor={
          highlightColorAlpha ? rgba(theme.colors[highlightColor], highlightColorAlpha) : theme.colors[highlightColor]
        }
        aria-label="loading"
        data-testid={dataTestId || LOADING_SPINNER}
        tx={tx}
      />
    );
  },
);

Spinner.displayName = 'Spinner';

export default Spinner;
