import { createReducer } from '@reduxjs/toolkit';

import {
  addColorFilterValueAction,
  addFilterAction,
  addImportedKeywordsFilterValueAction,
  addItemTypeFilterAction,
  addOtherFilterValueAction,
  changeFilterTypeAction,
  clearCamerasFilterValuesAction,
  clearColorFilterValuesAction,
  clearCreatedDateFilterValueAction,
  clearCustomFieldFilterValuesAction,
  clearImportedKeywordsFilterValuesAction,
  clearItemTypeFiltersAction,
  clearModifiedDateFilterValueAction,
  clearOtherFiltersValuesAction,
  clearStringArrayFilterValuesAction,
  clearTagsFilterValuesAction,
  clearTakenDateFilterValueAction,
  clearUploadedDateFilterValueAction,
  clearUploadersFilterValuesAction,
  removeAllFiltersAction,
  removeColorFilterValueAction,
  removeFilterAction,
  removeFiltersAction,
  removeFiltersWithoutSelectionAction,
  removeImportedKeywordsFilterValueAction,
  removeItemTypeFilterAction,
  removeOtherFilterValueAction,
  setCameraMakeFilterValuesAction,
  setCameraModelsFilterValuesAction,
  setCreatedDateFilterEndDateAction,
  setCreatedDateFilterStartDateAction,
  setCreatedDateFilterTypeAction,
  setCustomFieldFilterLogicAction,
  setCustomFieldFilterValuesAction,
  setFiltersValuesAction,
  setImportedKeywordsFilterLogicAction,
  setImportedKeywordsFiltersValueAction,
  setModifiedDateFilterEndDateAction,
  setModifiedDateFilterStartDateAction,
  setModifiedDateFilterTypeAction,
  setOtherFiltersValuesAction,
  setSelectedFiltersAction,
  setStringArrayFilterValuesAction,
  setTagsFilterLogicAction,
  setTagsFiltersValueAction,
  setTakenDateFilterEndDateAction,
  setTakenDateFilterStartDateAction,
  setTakenDateFilterTypeAction,
  setUploadedDateFilterEndDateAction,
  setUploadedDateFilterStartDateAction,
  setUploadedDateFilterTypeAction,
  setUploadersFilterValuesAction,
} from '~/store/filters/actions';
import { initialFiltersState, initialSelectedCustomField } from '~/store/filters/types';
import { resetFilterStateToInitialState, shouldKeepFilter } from '~/store/filters/utils';

export const reducer = createReducer(initialFiltersState, (builder) => {
  builder
    .addCase(addFilterAction, (state, { payload: { filter } }) => {
      state.selectedFilters.push(filter);

      resetFilterStateToInitialState(state, filter.type, filter.customField ? [filter.customField] : []);
    })
    .addCase(removeAllFiltersAction, () => {
      return initialFiltersState;
    })
    .addCase(removeFiltersAction, (state, { payload: { filters, customFields } }) => {
      state.selectedFilters = state.selectedFilters.filter((selected) => {
        if (selected.type === 'customField') {
          return !customFields.find((customField) => customField?.id === selected.customField?.id);
        } else {
          return !filters.find((type) => type === selected.type);
        }
      });
      filters.forEach((filter) => resetFilterStateToInitialState(state, filter, customFields));
    })
    .addCase(removeFilterAction, (state, { payload: { filter } }) => {
      state.selectedFilters = state.selectedFilters.filter((f) => {
        return filter.type === 'customField' && f.type === 'customField'
          ? f.customField?.id !== filter.customField?.id
          : f.type !== filter.type;
      });
      resetFilterStateToInitialState(state, filter.type, filter.customField ? [filter.customField] : []);
    })
    .addCase(removeFiltersWithoutSelectionAction, (state) => {
      return {
        ...state,
        customFields: state.customFields.filter((cf) => cf.values.length > 0),
        selectedFilters: state.selectedFilters.filter((selectedFilter) => shouldKeepFilter(selectedFilter.type, state)),
      };
    })
    .addCase(changeFilterTypeAction, (state, { payload: { oldFilter, newFilter } }) => {
      state.selectedFilters = state.selectedFilters.map((f) =>
        f.type === oldFilter.type && f.customField?.id === oldFilter.customField?.id ? newFilter : f,
      );
      resetFilterStateToInitialState(state, oldFilter.type, oldFilter.customField ? [oldFilter.customField] : []);
      resetFilterStateToInitialState(state, newFilter.type, newFilter.customField ? [newFilter.customField] : []);
    })
    .addCase(setFiltersValuesAction, (state, { payload: { filters } }) => {
      return {
        ...state,
        ...filters,
      };
    })
    .addCase(setSelectedFiltersAction, (state, { payload: { filters } }) => {
      state.selectedFilters = filters;
    })
    .addCase(addItemTypeFilterAction, (state, { payload: { type } }) => {
      state.types.push(type);
    })
    .addCase(removeItemTypeFilterAction, (state, { payload: { type } }) => {
      state.types = state.types.filter((t) => t !== type);
    })
    .addCase(clearItemTypeFiltersAction, (state) => {
      state.types = [];
    })
    .addCase(addColorFilterValueAction, (state, { payload: { color } }) => {
      state.colors.push(color);
    })
    .addCase(removeColorFilterValueAction, (state, { payload: { color } }) => {
      state.colors = state.colors.filter((c) => c !== color);
    })
    .addCase(clearColorFilterValuesAction, (state) => {
      state.colors = [];
    })
    .addCase(setTagsFilterLogicAction, (state, { payload: { logic } }) => {
      state.tags.logic = logic;
    })
    .addCase(setTagsFiltersValueAction, (state, { payload: { tags } }) => {
      state.tags.tags = tags;
    })
    .addCase(clearTagsFilterValuesAction, (state) => {
      state.tags = initialFiltersState.tags;
    })
    .addCase(setUploadersFilterValuesAction, (state, { payload: { uploaders } }) => {
      state.uploaders = uploaders;
    })
    .addCase(clearUploadersFilterValuesAction, (state) => {
      state.uploaders = [];
    })
    .addCase(addOtherFilterValueAction, (state, { payload: { filter } }) => {
      state.others.push(filter);
    })
    .addCase(removeOtherFilterValueAction, (state, { payload: { filter } }) => {
      state.others = state.others.filter((f) => f !== filter);
    })
    .addCase(setOtherFiltersValuesAction, (state, { payload: { filters } }) => {
      state.others = filters;
    })
    .addCase(clearOtherFiltersValuesAction, (state) => {
      state.others = [];
    })
    .addCase(setCustomFieldFilterValuesAction, (state, { payload: { values, customFieldId } }) => {
      const fieldIndex = state.customFields.findIndex(({ id }) => id === customFieldId);
      if (!state.customFields[fieldIndex]) {
        state.customFields.push({ ...initialSelectedCustomField, id: customFieldId, values });
      } else {
        state.customFields[fieldIndex].values = values;
      }
    })
    .addCase(setCustomFieldFilterLogicAction, (state, { payload: { logic, customFieldId } }) => {
      const fieldIndex = state.customFields.findIndex(({ id }) => id === customFieldId);
      if (!state.customFields[fieldIndex]) {
        state.customFields.push({ ...initialSelectedCustomField, id: customFieldId, logic });
      } else {
        state.customFields[fieldIndex].logic = logic;
      }
    })
    .addCase(clearCustomFieldFilterValuesAction, (state, { payload: { customFieldId } }) => {
      const fieldIndex = state.customFields.findIndex(({ id }) => id === customFieldId);
      if (!state.customFields[fieldIndex]) {
        state.customFields.push({ ...initialSelectedCustomField, id: customFieldId });
      } else {
        state.customFields[fieldIndex].values = [];
      }
    })
    .addCase(setCreatedDateFilterTypeAction, (state, { payload: { type } }) => {
      state.createdDate.type = type;
    })
    .addCase(setCreatedDateFilterStartDateAction, (state, { payload: { startDate } }) => {
      state.createdDate.startDate = startDate?.toISOString();
    })
    .addCase(clearCreatedDateFilterValueAction, (state) => {
      state.createdDate = initialFiltersState.createdDate;
    })
    .addCase(setCreatedDateFilterEndDateAction, (state, { payload: { endDate } }) => {
      state.createdDate.endDate = endDate?.toISOString();
    })
    .addCase(setModifiedDateFilterTypeAction, (state, { payload: { type } }) => {
      state.modifiedDate.type = type;
    })
    .addCase(setModifiedDateFilterStartDateAction, (state, { payload: { startDate } }) => {
      state.modifiedDate.startDate = startDate?.toISOString();
    })
    .addCase(clearModifiedDateFilterValueAction, (state) => {
      state.modifiedDate = initialFiltersState.modifiedDate;
    })
    .addCase(setModifiedDateFilterEndDateAction, (state, { payload: { endDate } }) => {
      state.modifiedDate.endDate = endDate?.toISOString();
    })
    .addCase(setUploadedDateFilterTypeAction, (state, { payload: { type } }) => {
      state.uploadedDate.type = type;
    })
    .addCase(clearUploadedDateFilterValueAction, (state) => {
      state.uploadedDate = initialFiltersState.uploadedDate;
    })
    .addCase(setUploadedDateFilterStartDateAction, (state, { payload: { startDate } }) => {
      state.uploadedDate.startDate = startDate?.toISOString();
    })
    .addCase(setUploadedDateFilterEndDateAction, (state, { payload: { endDate } }) => {
      state.uploadedDate.endDate = endDate?.toISOString();
    })
    .addCase(setStringArrayFilterValuesAction, (state, { payload: { filter, values } }) => {
      state[filter] = values;
    })
    .addCase(clearStringArrayFilterValuesAction, (state, { payload: { filter } }) => {
      state[filter] = [];
    })
    .addCase(addImportedKeywordsFilterValueAction, (state, { payload: { keyword } }) => {
      state.importedKeywords.keywords.push(keyword);
    })
    .addCase(setImportedKeywordsFilterLogicAction, (state, { payload: { logic } }) => {
      state.importedKeywords.logic = logic;
    })
    .addCase(setImportedKeywordsFiltersValueAction, (state, { payload: { keywords } }) => {
      state.importedKeywords.keywords = keywords;
    })
    .addCase(removeImportedKeywordsFilterValueAction, (state, { payload: { keyword } }) => {
      state.importedKeywords.keywords = state.importedKeywords.keywords.filter((k) => k !== keyword);
    })
    .addCase(clearImportedKeywordsFilterValuesAction, (state) => {
      state.importedKeywords = initialFiltersState.importedKeywords;
    })
    .addCase(setTakenDateFilterTypeAction, (state, { payload: { type } }) => {
      state.takenDate.type = type;
    })
    .addCase(setTakenDateFilterStartDateAction, (state, { payload: { startDate } }) => {
      state.takenDate.startDate = startDate?.toISOString();
    })
    .addCase(clearTakenDateFilterValueAction, (state) => {
      state.takenDate = initialFiltersState.takenDate;
    })
    .addCase(setTakenDateFilterEndDateAction, (state, { payload: { endDate } }) => {
      state.takenDate.endDate = endDate?.toISOString();
    })
    .addCase(setCameraMakeFilterValuesAction, (state, { payload: { make } }) => {
      state.camera.make = make;
    })
    .addCase(setCameraModelsFilterValuesAction, (state, { payload: { models } }) => {
      state.camera.models = models;
    })
    .addCase(clearCamerasFilterValuesAction, (state) => {
      state.camera = initialFiltersState.camera;
    });
});
