import { Color } from '@air/api/types';
import { FilterLookupsResponse } from '@air/types/src/search';

import { CustomFieldValueFilterBase } from '~/components/Filters/CustomFieldFilter/utils';
import { ItemTypeFilter } from '~/components/Filters/types';
import { CustomFieldFilterType, FilterType } from '~/components/FiltersMenu/types';
import { TagBase } from '~/components/TagsSelect/utils';

export type SourceFilterType = Omit<FilterLookupsResponse['sources'][0], 'count'> & { count?: number };
export type ExtensionFilterType = Omit<FilterLookupsResponse['extensions'][0], 'count'> & { count?: number };
export type UploaderFilterType = Omit<FilterLookupsResponse['uploaders'][0], 'count'> & { count?: number };

export type FilterLogicType = 'and' | 'or' | 'not';

export type DateRangeType =
  | 'all-time'
  | 'today'
  | 'yesterday'
  | 'last-week'
  | 'last-month'
  | 'last-year'
  | 'custom-range';

export interface SelectedFilter {
  type: FilterType;
  customField?: CustomFieldFilterType;
}

export interface DateFilter {
  type: DateRangeType;
  startDate?: string;
  endDate?: string;
}

export interface TagsFilterType {
  tags: TagBase[];
  logic: FilterLogicType;
}

export interface ImportedKeywordsFilterType {
  keywords: string[];
  logic: FilterLogicType;
}

export type OtherFiltersType = 'is-favorited' | 'has-open-comments' | 'has-versions' | 'is-on-boards' | 'is-untagged';

export interface CustomFieldSelectedFilter {
  id: CustomFieldFilterType['id'];
  logic: FilterLogicType;
  values: CustomFieldValueFilterBase[];
}

export interface CameraFilter {
  make: string;
  models: string[];
}

export const initialSelectedCustomField: CustomFieldSelectedFilter = {
  id: '',
  values: [],
  logic: 'and',
};

interface StringArrayFilters {
  countries: string[];
  creators: string[];
  copyrights: string[];
  cities: string[];
  states: string[];
  videoFrameRates: string[];
  extensions: string[];
  videoAspectRatios: string[];
  sources: string[];
  audioCodings: string[];
  audioSampleRates: string[];
}

export type StringArrayFilterType = keyof StringArrayFilters;

export interface Filters extends StringArrayFilters {
  types: ItemTypeFilter[];
  colors: Color['hex'][];
  tags: TagsFilterType;
  uploaders: UploaderFilterType[];
  others: OtherFiltersType[];
  customFields: CustomFieldSelectedFilter[];
  createdDate: DateFilter;
  modifiedDate: DateFilter;
  uploadedDate: DateFilter;
  takenDate: DateFilter;
  importedKeywords: ImportedKeywordsFilterType;
  camera: CameraFilter;
}

export interface FiltersState extends Filters {
  selectedFilters: SelectedFilter[];
}

export const initialFiltersState: FiltersState = {
  selectedFilters: [],
  types: [],
  colors: [],
  tags: { logic: 'and', tags: [] },
  sources: [],
  uploaders: [],
  extensions: [],
  others: [],
  customFields: [],
  createdDate: { type: 'all-time' },
  modifiedDate: { type: 'all-time' },
  uploadedDate: { type: 'all-time' },
  takenDate: { type: 'all-time' },
  importedKeywords: { logic: 'and', keywords: [] },
  countries: [],
  copyrights: [],
  creators: [],
  camera: { make: '', models: [] },
  cities: [],
  states: [],
  videoFrameRates: [],
  videoAspectRatios: [],
  audioCodings: [],
  audioSampleRates: [],
};

export type SelectedFilterValueObject = {
  key: keyof Filters;
  value: Filters[keyof Filters];
};
