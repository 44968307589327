// these 3 will not always be true. Rig it up in the future to have these
// heights be coming from refs and stored in Workspace provider
export const WKSPC_FILTER_HEIGHT = 34;
export const WKSPC_VIEW_CONTROL_HEIGHT = 45; // account for border

export const WKSPC_MOBILE_HORIZONTAL_PADDING = 12;
export const WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_OPEN = 48;
export const WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_CLOSED = 48;
export const SEARCH_INITIAL_HEADER_HEIGHT = 66;
export const WKSPC_CLIP_INFO_HEIGHT = 48;
export const WKSPC_CUSTOM_FIELD_DISPLAY_HEIGHT = 26;

export const WKSPC_GALLERY_BUTTON_FOCUS_BORDER_WIDTH = 4;
export const WKSPC_GALLERY_FIRST_SECTION_GAP_MOBILE = 40 + WKSPC_GALLERY_BUTTON_FOCUS_BORDER_WIDTH;
export const WKSPC_GALLERY_FIRST_SECTION_GAP_DESKTOP = 40 + WKSPC_GALLERY_BUTTON_FOCUS_BORDER_WIDTH;

export const SEARCH_STATIC_HEADER_Z_INDEX = 5;

export const SELECTION_BAR_MEDIUM_HEIGHT = 64;
export const UPLOAD_PANE_WIDTH = 370;
export const UPLOAD_PANE_WIDTH_CLASSNAME = `w-[370px]`;

export const BOARD_COLLECTION_HERO_HEIGHT = 56;
export const BOARD_COLLECTION_ACTION_BAR_HEIGHT = 72;
export const BOARD_COLLECTION_VIEW_WRAPPER_PADDING = 48;

export const HEADER_MOBILE_HORIZONTAL_PADDING = 12;
export const HEADER_DESKTOP_HORIZONTAL_PADDING = 48;
export const PUBLIC_TABLE_HEADER_PADDING = [
  HEADER_MOBILE_HORIZONTAL_PADDING,
  HEADER_MOBILE_HORIZONTAL_PADDING,
  HEADER_DESKTOP_HORIZONTAL_PADDING + 12,
];
