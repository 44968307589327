import { Fragment, type ReactNode } from 'react';

import { type ContextMenuVariant } from '../ContextMenu';
import { ContextMenuItem, ContextMenuItemProps } from '../ContextMenuItem';
import { ContextMenuLabel } from '../ContextMenuLabel';
import { ContextMenuSeparator } from '../ContextMenuSeparator';
import { ContextMenuSub } from '../ContextMenuSub';

export type ContextMenuOptionBase = {
  id: string;
};

export type ContextMenuOptionChildren = ContextMenuOptionBase & {
  children: ReactNode;
  type: 'children';
};

export type ContextMenuOptionRadioGroup = ContextMenuOptionBase & {
  options?: ContextMenuOptionRadioItem[];
  type: 'radio-group';
};

export type ContextMenuOptionRadioItem = ContextMenuOptionBase &
  Omit<ContextMenuOptionItem, 'suffix' | 'type'> & {
    type: 'radio-item';
    value: string;
  };

export type ContextMenuOptionSeparator = ContextMenuOptionBase & {
  className?: string;
  type: 'separator';
};

export type ContextMenuOptionTitle = ContextMenuOptionBase & {
  className?: string;
  label: string;
  type: 'title';
};

export type ContextMenuOptionSub = Omit<ContextMenuOptionItem, 'suffix' | 'type'> & {
  options: ContextMenuOption[];
  type: 'sub';
};

export type ContextMenuOption =
  | ContextMenuOptionChildren
  | ContextMenuOptionItem
  | ContextMenuOptionRadioItem
  | ContextMenuOptionRadioGroup
  | ContextMenuOptionSeparator
  | ContextMenuOptionTitle
  | ContextMenuOptionSub;

export type ContextMenuOptionItem = ContextMenuOptionBase &
  Pick<ContextMenuItemProps, 'disabled'> & {
    className?: string;
    description?: string;
    label: ReactNode;
    onSelect?: () => void;
    prefix?: ReactNode;
    shortcut?: string[];
    suffix?: ReactNode;
    type: 'item';
  };

export type RenderContextItemsParams = {
  options?: ContextMenuOption[];
  size?: ContextMenuVariant['size'];
};

export const renderContextItems = ({ options, size = 'small' }: RenderContextItemsParams) => {
  if (!options) return null;

  return options.map((option, index) => {
    switch (option.type) {
      case 'children':
        return <Fragment key={option.id || index}>{option.children}</Fragment>;
      case 'item':
        return (
          <ContextMenuItem
            className={option.className}
            data-id={option.id}
            description={option.description}
            disabled={option.disabled}
            label={option.label}
            onSelect={option.onSelect}
            prefix={option.prefix}
            shortcut={option.shortcut}
            size={size}
            suffix={option.suffix}
            key={option.id || index}
          />
        );
      case 'radio-group':
        return null;
      case 'radio-item':
        return null;
      case 'separator':
        return <ContextMenuSeparator className={option.className} data-id={option.id} key={option.id || index} />;
      case 'sub':
        return (
          <ContextMenuSub
            className={option.className}
            data-id={option.id}
            description={option.description}
            disabled={option.disabled}
            label={option.label}
            onSelect={option.onSelect}
            prefix={option.prefix}
            size={size}
            key={option.id || index}
          >
            {renderContextItems({ options: option.options, size })}
          </ContextMenuSub>
        );
      case 'title':
        return (
          <ContextMenuLabel className={option.className} data-id={option.id} key={option.id || index} size={size}>
            {option.label}
          </ContextMenuLabel>
        );
      default:
        return null;
    }
  });
};
