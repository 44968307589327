import { FieldVariantName, TextVariantName, TXProp } from '@air/zephyr';

type SelectVariantName = FieldVariantName | 'field-input-smol-16' | 'field-input-chonky-16';
export interface SelectVariantStylesType {
  chip: {
    tx: TXProp;
    textVariant: TextVariantName;
  };
  fontSize: number;
  textVariant: TextVariantName;
  label: {
    tx: TXProp;
  };
  placeholder: {
    tx: TXProp;
  };
}

export const SelectVariantStyles: { [key in SelectVariantName]: SelectVariantStylesType } = {
  'field-input-smol': {
    chip: {
      tx: {
        px: 8,
        py: 3,
      },
      textVariant: 'text-ui-12',
    },
    fontSize: 12,
    textVariant: 'text-ui-12',
    label: {
      tx: { height: 24, px: 6, py: 2 },
    },
    placeholder: {
      tx: {
        px: 2,
        py: 3,
      },
    },
  },
  'field-input-chonky': {
    chip: {
      tx: {
        px: 12,
        py: 6,
      },
      textVariant: 'text-ui-14',
    },
    fontSize: 14,
    textVariant: 'text-ui-14',
    label: {
      tx: { height: 32, px: 10, py: 4 },
    },
    placeholder: {
      tx: {
        px: 2,
        py: 6,
      },
    },
  },
  'field-input-smol-16': {
    chip: {
      tx: {
        px: 8,
        py: 3,
      },
      textVariant: 'text-ui-16',
    },
    fontSize: 16,
    textVariant: 'text-ui-16',
    label: {
      tx: { height: 24, px: 6, py: 2 },
    },
    placeholder: {
      tx: {
        px: 2,
        py: 0,
      },
    },
  },
  'field-input-chonky-16': {
    chip: {
      tx: {
        px: 12,
        py: 6,
      },
      textVariant: 'text-ui-16',
    },
    fontSize: 16,
    textVariant: 'text-ui-16',
    label: {
      tx: { height: 32, px: 10, py: 4 },
    },
    placeholder: {
      tx: {
        px: 2,
        py: 4,
      },
    },
  },
};
