import { Box, BoxStylingProps, Error, InputPrimitive, InputPrimitiveProps, LabelPrimitive } from '@air/zephyr';
import VisuallyHidden from '@reach/visually-hidden';
import React, { forwardRef } from 'react';
import { useTheme } from 'styled-components';
import { variant as styledSystemVariant } from 'styled-system';

import { SpacingStyles } from './types';

export interface InputProps extends Omit<InputPrimitiveProps, 'tx'> {
  /**
   * Used to offer a "subtitle" to a label, where you can expand on an input's needs. You can also use this to provide
   * helpful context to people using screen readers.
   */
  description?: {
    isHidden: boolean;
    component: React.ReactNode;
  };
  errorText?: string;
  /**
   * By default, this value is set to false and any errors incurred by this component will display below the input field. If you'd like to use
   * alternative tools to handle displaying your errors (i.e. Formik's `<ErrorMessage>` component), then you should set this prop to false to
   * prevent duplicate, or ill-formatted error messages.
   */
  isErrorHidden?: boolean;
  /**
   * An input must always have a label, but design may want it visually hidden.
   */
  isLabelHidden?: boolean;
  /**
   * The content of the label. No need for * when required - it's added automatically.
   */
  label: string;
  leftAdornment?: React.ReactNode;
  leftAdornmentClickThrough?: boolean;
  /**
   * This is used to identify the Input. Although this is not specifically a Formik Input, the name is necessary for the `for` prop on the label,
   */
  name: string;
  rightAdornment?: React.ReactNode;
  /**
   * The entirety of the `tx` prop is not permitted on this input. You may only modify styles that effect the spacing of the input and the label.
   * You can add/remove items from the list of permitted styles by viewing the file listed below.
   * @see {@link SpacingStyles}
   */
  spacingStyles?: SpacingStyles;
}

const sharedAdornmentStyles: BoxStylingProps['tx'] = {
  color: 'pigeon500',
  position: 'absolute',
};

export const Input = forwardRef<HTMLDivElement, InputProps>(
  (
    {
      autoComplete,
      description,
      errorText,
      id,
      label,
      leftAdornment,
      leftAdornmentClickThrough,
      name,
      placeholder,
      required,
      rightAdornment,
      spacingStyles,
      disabled = false,
      isErrorHidden = false,
      isLabelHidden = false,
      readOnly = false,
      type = 'text',
      variant = 'field-input-smol',
      'data-testid': testId,
      ...restOfProps
    }: InputProps,
    ref,
  ) => {
    const theme = useTheme();
    const inputIdentifier = id ?? name;
    const errorIdentifier = `${inputIdentifier}_error`;
    const descriptionIdentifier = `${inputIdentifier}_description`;
    const isChonky = variant === 'field-input-chonky';

    const adornmentSideBuffer = React.useMemo(
      () =>
        styledSystemVariant({
          prop: 'variant',
          variants: {
            'field-input-chonky': {
              pl: 40,
              pr: 40,
            },
            'field-input-smol': {
              pl: 36,
              pr: 36,
            },
          },
        })({ theme, variant }) as {
          paddingLeft: number | number[];
          paddingRight: number | number[];
        },
      [theme, variant],
    );

    const nonAdornmentSideBuffer = React.useMemo(
      () =>
        styledSystemVariant({
          prop: 'variant',
          variants: {
            'field-input-chonky': {
              pl: 16,
              pr: 16,
            },
            'field-input-smol': {
              pl: 12,
              pr: 12,
            },
          },
        })({ theme, variant }) as {
          paddingLeft: number | number[];
          paddingRight: number | number[];
        },
      [theme, variant],
    );

    return (
      <Box
        ref={ref}
        tx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          minWidth: '256px',
          justifyContent: 'center',
          alignItems: 'flex-start',
          ...spacingStyles,
        }}
      >
        {isLabelHidden ? (
          <VisuallyHidden>{label}</VisuallyHidden>
        ) : (
          <LabelPrimitive for={inputIdentifier} showAsterisk={required} size={isChonky ? 'text-ui-14' : 'text-ui-12'}>
            {label}
          </LabelPrimitive>
        )}

        <div className="relative w-full">
          {!!leftAdornment && (
            <Box
              tx={{
                ...sharedAdornmentStyles,
                ...styledSystemVariant({
                  prop: 'variant',
                  variants: {
                    'field-input-chonky': {
                      top: 14,
                      width: 20,
                      left: 14,
                    },
                    'field-input-smol': {
                      top: 12,
                      width: 16,
                      left: 12,
                    },
                  },
                })({ theme, variant }),
                ...(leftAdornmentClickThrough && { pointerEvents: 'none' }),
              }}
            >
              {leftAdornment}
            </Box>
          )}

          <InputPrimitive
            aria-invalid={!!errorText}
            aria-describedby={description ? `${descriptionIdentifier} ${errorIdentifier}` : errorIdentifier}
            autoComplete={autoComplete}
            data-testid={testId}
            disabled={disabled || readOnly}
            id={inputIdentifier}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required}
            tx={{
              pl: leftAdornment ? adornmentSideBuffer['paddingLeft'] : nonAdornmentSideBuffer['paddingLeft'],
              pr: rightAdornment ? adornmentSideBuffer['paddingRight'] : nonAdornmentSideBuffer['paddingRight'],
            }}
            type={type}
            variant={variant}
            name={name}
            {...restOfProps}
          />

          {!!rightAdornment && (
            <Box
              tx={{
                ...sharedAdornmentStyles,
                ...styledSystemVariant({
                  prop: 'variant',
                  variants: {
                    'field-input-chonky': {
                      top: 14,
                      width: 20,
                      right: 14,
                    },
                    'field-input-smol': {
                      top: 12,
                      width: 16,
                      right: 12,
                    },
                  },
                })({ theme, variant }),
              }}
            >
              {rightAdornment}
            </Box>
          )}
        </div>

        {(isErrorHidden || !errorText) && (
          <div
            className="absolute text-12 text-grey-10"
            id={descriptionIdentifier}
            data-testid={`${testId}_description`}
            style={{
              bottom: isChonky ? -22 : -18,
            }}
          >
            {description?.isHidden ? <VisuallyHidden>{description.component}</VisuallyHidden> : description?.component}
          </div>
        )}

        {!isErrorHidden && (
          <Error
            errorText={errorText}
            isErrorVisible={!isErrorHidden}
            id={errorIdentifier}
            tx={{ bottom: isChonky ? -22 : -18 }}
            data-testid={`${testId}_error`}
          />
        )}
      </Box>
    );
  },
);

Input.displayName = 'Input';
